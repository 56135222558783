import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

const PlansPage = ({ data, location }) => {
  const page = data.prismicPlans.data
  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="content">
        <div className="pt-32 lg:pt-36 mb-0 lg:mb-0">
          <div className="relative">
            <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
              <div className="sticker type-lg lg:type-3xl text-white">
                <div dangerouslySetInnerHTML={{ __html: page.title }} />
              </div>
            </div>
            <div className="grid">
              <div className="grid__half--primary lg:hidden">
                <div className="w-3/4">
                  <h2 className="type-xl lg:type-3xl text-white lg:mb-10">{page.title}</h2>
                </div>
              </div>
              <div className="grid__half--secondary rte pt-4">
                <div
                  className="lg:type-lg lg:mb-6 italic"
                  dangerouslySetInnerHTML={{ __html: page.lead.html }}
                />
                <div dangerouslySetInnerHTML={{ __html: page.body.html }} />
              </div>
            </div>
          </div>
        </div>
        {page.locations.map((item, i) => {
          return (
            <div key={i}>
              <div className="grid lg:mb-16">
                {/*<div className="grid__full">
                  <div className="relative flex justify-center items-center">
                    <img
                      src={item.location_image.url}
                      alt={item.location_image.alt}
                    />
                    <div className="absolute w-2/3 flex justify-center">
                      <img
                        src={item.location_name.url}
                        alt={item.location_name.alt}
                      />
                    </div>
                  </div>
                </div>{*/}
              </div>
              <div className="grid mb-0 lg:mb-4">
                <div className="grid__half--primary">
                  <img src={item.location_map.url} alt={item.location_map.alt} />
                </div>

                <div className="grid__half--secondary">
                  <h2 className="type-xl lg:type-3xl text-white mb-4 lg:mb-10 pt-6 md:pt-10 lg:pt-0">
                    {item.location_title}
                  </h2>
                  <div
                    className="rte"
                    dangerouslySetInnerHTML={{ __html: item.location_body.html }}
                  />
                </div>
              </div>
              
            </div>
          )
        })}

        <div className="pb-5" id="our-future">
          <div className="relative">
            <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
              <div className="sticker type-lg lg:type-3xl text-white">
                <div dangerouslySetInnerHTML={{ __html: page.our_future_title.text }} />
              </div>
            </div>
            <div className="grid">
              <div className="grid__half--primary lg:hidden">
                <div className="w-3/4">
                  <h2 className="type-xl lg:type-3xl text-white lg:mb-20 ">{page.our_future_title.text}</h2>
                </div>
              </div>
              <div className="grid__half--secondary rte pt-4">
                
                <div dangerouslySetInnerHTML={{ __html: page.our_future_content.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

PlansPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pagePlansQuery = graphql`
  query {
    prismicPlans {
      data {
        title
        lead {
          html
        }
        body {
          html
          text
        }        
        locations {
          location_title
          location_image {
            alt
            url
          }
          location_name {
            alt
            url
          }
          location_body {
            html
            text
          }
          location_map {
            alt
            url
          }
        }
        our_future_title{
          text
        }
        our_future_content{
          text
          html
        }
        recent_updates_title{
          text
        }
        recent_updates_content{
          html
        }
        recent_updates_video_poster{
          url
        }
        recent_updates_video{
          url
        }
        meta_title
        meta_description
      }
    }
  }
`

export default PlansPage
